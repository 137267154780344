<template>
  <b-row>
    <b-col>
      <b-card>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="col-ms-4" scope="col"></th>
                <th class="col-ms-4" scope="col">
                  <strong>現在地点</strong>
                </th>
                <th class="col-ms-4" scope="col">
                  <strong>目標地点</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">緯度</th>
                <td>{{lat}}</td>
                <td>{{destLat}}</td>
              </tr>
              <tr>
                <th scope="row">経度</th>
                <td>{{lon}}</td>
                <td>{{destLon}}</td>
              </tr>
              <tr>
                <th scope="row">現在の角度</th>
                <td>{{degree}}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">目標までの距離</th>
                <td>{{distance}}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: ["lat", "lon", "degree", "distance", "destLat", "destLon"]
};
</script>

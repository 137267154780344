<template>
  <div>
    <ros-status :ros="this.ros" @rosConnected="rosConnected" @rosUnConnected="rosUnConnected"></ros-status>
    <ros ref="rosObject" :url="this.rosIp" @getRosObject="setRos"></ros>
    <b-card>
      <robot-form v-on:getRobot="setRosIp"></robot-form>
      <b-button @click="reconnection" variant="primary">再接続</b-button>
    </b-card>
    <b-card>
      <google-map ref="gmapObject" v-bind:lat="lat" v-bind:lng="lon" v-bind:degree="degree"></google-map>
    </b-card>
    <!-- <b-card>
      <robot-status-table
        v-bind:lat="lat"
        v-bind:lon="lon"
        v-bind:degree="degree"
        v-bind:distance="distance"
        v-bind:destLat="destLat"
        v-bind:destLon="destLon"
      ></robot-status-table>
    </b-card>-->
    <manual-operation
      @stop="OperationHandlar"
      @advance="OperationHandlar"
      @turnRight="OperationHandlar"
      @turnLeft="OperationHandlar"
      @retreat="OperationHandlar"
    ></manual-operation>

    <!-- <robot-target-position @setTargetPosition="positionHandler"></robot-target-position> -->
  </div>
</template>

<script>
import ROSLIB from "roslib";
import Ros from "../components/Ros";
import GoogleMap from "../components/GoogleMap";
import RobotStatusTable from "../components/RobotStatusTable";
import RobotTargetPosition from "../components/RobotTargetPosition";
import RobotForm from "../components/form/RobotForm";
import RosStatus from "../components/RosStatus";
import ManualOperation from "../components/ManualOperation";

export default {
  components: {
    Ros,
    GoogleMap,
    RobotStatusTable,
    RobotTargetPosition,
    ManualOperation,
    RobotForm,
    RosStatus
  },
  data() {
    return {
      lat: null,
      lon: null,
      degree: 0,
      distance: 0,
      destLat: null,
      destLon: null,
      rosObj: null,
      posTopic: null,
      degreeTopic: null,
      distTopic: null,
      savePosService: null,
      runClient: null,
      rosIp: null,
      ros: null
    };
  },
  methods: {
    setRosIp(value) {
      this.rosIp = value.ip;
    },
    setRos(value) {
      this.ros = value;
    },
    rosConnected() {
      this.setPos();
      this.setDegree();
      this.setDistance();
    },
    rosUnConnected() {
      // 初期化
      // マップのロボットマーカーを非表示
      this.lat = null;
      this.lon = null;
      this.degree = 0;
    },
    reconnection() {
      this.$refs.rosObject.init();
    },
    setPos() {
      var _this = this;
      this.posTopic = this.$refs.rosObject.createTopicWithMsgType(
        "/legmin_himawari_magellan_rtk/rtk",
        "/legmin_himawari_magellan_rtk/rtk_pos"
      );
      this.posTopic.subscribe(function(message) {
        _this.lat = message.lat;
        _this.lon = message.lon;
      });
    },
    setDegree() {
      let _this = this;
      this.degreeTopic = this.$refs.rosObject.createTopic(
        "/legmin_himawari_mi/magnetism_azimuth"
      );
      this.degreeTopic.subscribe(function(message) {
        _this.degree = message.data;
      });
    },
    setDistance() {
      let _this = this;
      this.distTopic = this.$refs.rosObject.createTopic(
        "/legmin_himawari_navigation/target_distance"
      );
      this.distTopic.subscribe(function(message) {
        _this.distance = message.data;
      });
    },
    savePosition(targetLat, targetLon) {
      let _this = this;
      this.savePosService = this.$refs.rosObject.createService(
        "/legmin_himawari_position/save_one_position",
        "/legmin_himawari_position/save_one_position"
      );
      let request = new ROSLIB.ServiceRequest({
        target_lat: targetLat,
        target_lon: targetLon
      });
      this.savePosService.callService(request, function(result) {
        console.log("Result for service call on " + result);
      });
    },
    run(run) {
      let _this = this;
      this.runClient = this.$refs.rosObject.createActionClient(
        "/legmin_himawari_run_to_position/server_run_to_position",
        "/legmin_himawari_run_to_position/RunToPositionAction"
      );
      let goal = new ROSLIB.Goal({
        actionClient: _this.runClient,
        goalMessage: { run: run }
      });
      goal.on("feedback", function(feedback) {
        // console.log('Feedback: ', feedback);
      });
      goal.on("result", function(result) {
        console.log("Final Result: ", result);
      });
      goal.send();
    },
    manualRun(runType, motor) {
      let _this = this;
      this.runClient = this.$refs.rosObject.createActionClient(
        "/legmin_himawari_manual_run/server_run",
        "/legmin_himawari_manual_run/RunAction"
      );

      let goal = new ROSLIB.Goal({
        actionClient: _this.runClient,
        goalMessage: {
          run_type: runType,
          motor: parseInt(motor)
        }
      });
      goal.on("feedback", function(feedback) {
        // console.log('Feedback: ', feedback);
      });
      goal.on("result", function(result) {
        console.log("Final Result: ", result);
      });
      goal.send();
    },
    positionHandler(lat, lon) {
      this.destLat = lat;
      this.destLon = lon;
      this.$refs.gmapObject.addMarker(this.destLat, this.destLon);
      this.savePosition(this.destLat, this.destLon);
      this.run(true);
    },
    OperationHandlar(runType, moterValue) {
      this.manualRun(runType, moterValue);
    }
  },
  beforeDestroy() {
    if (this.ros) {
      this.ros.close();
    }
  }
};
</script>

<template>
  <b-card>
    <b-row>
      <b-col>
        <b-form>
          <b-form-group label="モーター" label-for="motor" :label-cols="3">
            <b-form-select id="motor" :options="speedMotor()" v-model="motorValue"></b-form-select>
          </b-form-group>
          <div slot="footer">
            <b-row>
              <b-col cols="12" lg="12">
                <b-button block variant="primary" @click="advance">
                  <i class="cui-arrow-top"></i> 前進
                </b-button>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col cols="6" lg="6">
                <b-button block variant="primary" @click="turnLeft">
                  <i class="cui-action-undo"></i> 左旋回
                </b-button>
              </b-col>
              <b-col cols="6" lg="6">
                <b-button block variant="primary" @click="turnRight">
                  <i class="cui-action-redo"></i> 右旋回
                </b-button>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col cols="12" lg="12">
                <b-button block variant="primary" @click="retreat">
                  <i class="cui-arrow-bottom"></i> 後退
                </b-button>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col cols="12" lg="12">
                <b-button block variant="danger" @click="stop">
                  <i class="fa fa-ban"></i> 停止
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      motorValue: 200
    };
  },
  methods: {
    speedMotor() {
      let maxSpeed = 1200;
      let motor = [];
      for (let i = 0; 50 * i <= maxSpeed; i++) {
        motor.push(50 * i);
      }
      return motor;
    },
    stop() {
      this.$emit("stop", 0, 0);
    },
    advance() {
      this.$emit("advance", 1, this.motorValue);
    },
    turnRight() {
      this.$emit("turnRight", 2, this.motorValue);
    },
    turnLeft() {
      this.$emit("turnLeft", 3, this.motorValue);
    },
    retreat() {
      this.$emit("retreat", 4, this.motorValue);
    }
  }
};
</script>

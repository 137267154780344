<template>
  <b-row>
    <b-col>
      <b-card>
        <b-form>
          <b-form-group
            label="緯度"
            lable-for="targetLat"
            description="Please enter target longitude."
            :label-cols="3"
          >
            <b-form-input id="targetLat" type="number" v-model.number="lat" />
          </b-form-group>
          <b-form-group
            label="経度"
            label-for="targetLon"
            description="Please enter target latitude."
            :label-cols="3"
          >
            <b-form-input id="targetLon" v-model.number="lon" />
          </b-form-group>
          <div slot="footer">
            <b-button size="sm" variant="primary" @click="setTargetPosition">
              <i class="fa fa-dot-circle-o"></i> 設定
            </b-button>
            <b-button size="sm" variant="danger" @click="resetPosition">
              <i class="fa fa-ban"></i> リセット
            </b-button>
          </div>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      lat: 35.09263411632531,
      lon: 138.9066779004375
    };
  },
  methods: {
    setTargetPosition() {
      this.$emit("setTargetPosition", this.lat, this.lon);
    },
    resetPosition() {
      this.lat = null;
      this.lon = null;
    }
  }
};
</script>